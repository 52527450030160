<script>
  import CInterpreter from '@/components/grid/cells/skill/interpretation/CInterpreter';

  export default {
    extends: CInterpreter,
    computed: {
      icons() {
        return [
          {
            id: Math.random(),
            type: 'icon',
            classes: ['phone-icon'],
            noValueClasses: ['phone-icon--grey'],
            hint: this.phone,
            hasValue: this.phone
          },
          {
            id: Math.random(),
            type: 'icon',
            classes: ['alternative-phone-icon'],
            noValueClasses: ['alternative-phone-icon--grey'],
            hint: this.alternativePhone,
            hasValue: this.alternativePhone
          },
          {
            id: Math.random(),
            type: 'icon',
            classes: ['email-icon'],
            noValueClasses: ['email-icon---grey'],
            hint: this.email,
            hasValue: this.email
          },
          {
            id: Math.random(),
            type: 'avatar',
            avatar: this.avatarUrl
          }
        ];
      },
      person() { return this.propData?.person; },
      name() { return this.person?.name; },
      email() { return this.person?.email; },
      phone() { return this.person?.phone; },
      avatarUrl() { return this.person?.avatarUrl; },
      alternativePhone() { return this.person?.alternativePhone; }
    },
    methods: {
      openCreateSkillModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-inter-skill-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Add skill'),
            context: this,
            successHandlingCallbackName: 'refreshTranslationSkillsGrid'
          }
        });
      },
      changeAddBtnVisibility(visibility) {
        this.addBtnVisibility = visibility;
      },
      cellFocusOver() {
        // this.changeAddBtnVisibility(false);
        this.closeTooltip();
      },
      cellFocus(event) {
        // this.changeAddBtnVisibility(true);
        if (this.isBirthDayWeek && this.birthDate && event.target.classList.contains('person__wrapper--birthday-week')) this.openTooltip(this.birthDayHintText, event);
      },
      refreshTranslationSkillsGrid() {
        this.$store.commit('AllTranslationSkillsStore/refreshTranslationSkillGrid');
      }
    }
  };
</script>

<template>
  <p>{{ activeFrom }}</p>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      activeFrom() { return this.propData?.activeFrom || '-'; }
    }
  };
</script>

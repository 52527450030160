<template>
  <div class="skill-info">
    <div class="skill-info_wrapper">
      <div class="skill-info__info overflow-ellipsis">
        <div class="skill-info__languages-wrapper">
          <span v-if="nativeFrom"
                class="tick__icon pointer"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip($gettext('Native'), $event)"></span>
          <p class="pointer"
             @mouseleave="closeTooltip"
             @mouseover="openTooltip(langFrom, $event)">{{ langFrom }}</p>
          <span class="arrow-right__icon"></span>
          <span v-if="nativeTo"
                class="tick__icon pointer"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip($gettext('Native'), $event)"></span>
          <p class="pointer"
             @mouseleave="closeTooltip"
             @mouseover="openTooltip(langTo, $event)">{{ langTo }}</p>
        </div>
        <p class="category-level">
          <span v-if="qualificationShortName">({{ qualificationShortName }})</span>
        </p>
      </div>
      <div class="skill-info__skill-status">
        <sk-chip :label="skillStatusText"
                 :color="skillStatusColor" />
      </div>
    </div>
  </div>
</template>

<script>
  import CSkillInfo from '@/components/grid/cells/skill/interpretation/CSkillInfo';

  export default {
    extends: CSkillInfo,
    computed: {
      qualificationsList() { return this.$store.getters.translationQualifications; },
      nativeFrom() { return this.propData?.nativeFrom || false; },
      nativeTo() { return this.propData?.nativeTo || false; }
    }
  };
</script>
<style>
.skill-info__languages-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.skill-info .arrow-right__icon,
.skill-info .tick__icon {
  display: inline-block;
  width: 18px;
  height: 100%;
  background-image: url(~@assets/imgs/undefined_imgs/tick_icon_outlined.svg);
  background-position: 50% 40%;
  background-repeat: no-repeat;
}

.skill-info .arrow-right__icon {
  margin: 0 2px;
  background-image: url(~@assets/imgs/arrows/arrow_to_right_new_grey.svg);
}
</style>

(<template>
  <div class="translation-stats">
    <div v-if="statNotZero"
         class="translation-stats__wrapper">
      <span v-for="(stat, index) in statsList"
            :key="index"
            class="stat"
            @mouseleave="closeTooltip"
            @mouseover="openTooltip(stat.name, $event)">
        <template v-if="stat.value != null">
          <span
            v-if="index !== 0"
            class="divider">/</span>
          {{ stat.value }}
        </template>
      </span>
    </div>
    <p v-else>-</p>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      stats() { return this.propData?.translation?.statistics; },
      invitedStats() { return this.stats?.invited || 0; },
      appliedStats() { return this.stats?.applied || 0; },
      acceptedStats() { return this.stats?.accepted || 0; },
      inProgressStats() { return this.stats?.in_progress || 0; },
      requestReviewStat() { return this.stats?.review_requested || 0; },
      changesRequiredStat() { return this.stats?.changes_required || 0; },
      finishedStats() { return this.stats?.finished || 0; },
      secondStat() { return this.acceptedStats + this.appliedStats + this.inProgressStats; },
      thirdStat() { return this.requestReviewStat + this.changesRequiredStat + this.finishedStats; },
      statNotZero() { return this.invitedStats || this.secondStat || this.thirdStat; },
      statsList() {
        return [
          {
            name: this.$gettext('Invited'),
            value: this.invitedStats
          },
          {
            name: this.$gettext('Applied, accepted and in progress'),
            value: this.secondStat
          },
          {
            name: this.$gettext('Review requested, changes required and finished'),
            value: this.thirdStat
          }
        ];
      }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); }
    }
  };
</script>
<style scoped>
.translation-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.translation-stats__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.stat {
  cursor: pointer;
}

.divider {
  margin: 0 3px;
  color: #a7abbd;
}
</style>

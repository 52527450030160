<script>
  import QualificationFilter from '@/components/grid/filters/shared/QualificationFilter';

  export default {
    extends: QualificationFilter,
    computed: {
      qualificationsList() { return this.$store.getters.translationQualifications; }
    }
  };
</script>

<template>
  <div class="status-wrapper">
    <!------  Status Active  ------>
    <template v-if="status === 'active'">
      <div class="active-wrapper">
        <span class="tick-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip($gettext('Active'), $event)"></span>
        <p class="status__text">{{ $gettext('Active') }}</p>
      </div>
    </template>
    <!------  Status Paused  ------>

    <template v-if="status === 'paused'">
      <div class="paused-wrapper">
        <span class="paused-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(pausedHoverText, $event)"></span>
        <p class="status__text">{{ $gettext('Paused') }}</p>
      </div>
      <p class="status-paused-date__text">{{ pausedTillDate }}</p>
    </template>

    <!------  Negative Statuses  ------>
    <template v-if="isNegativeStatus">
      <div class="blocked-wrapper">
        <span class="blocked-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(accountStatusType, $event)"></span>
        <p class="status__text">{{ accountStatusType }}</p>
      </div>
    </template>

  </div>
</template>

<script>
  import CStatus from '@/components/grid/cells/skill/interpretation/CStatus';

  export default {
    extends: CStatus,
  };
</script>

<script>
  import CTranslationStats from '@/components/grid/cells/shared/CTranslationStats';

  export default {
    extends: CTranslationStats,
    computed: {
      stats() { return this.propData?.statistics; },
    }
  };
</script>

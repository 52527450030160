<template>
  <section class="contract-filter">
    <sk-select :default-value="placeholder"
               :items-list="hasContractOptions"
               class="contract__select"
               :preselected-value="contract"
               @csvaluechanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        contract: ''
      };
    },
    computed: {
      hasContractOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear')},
          {id: '1', name: this.$gettext('Has Contract')},
          {id: '0', name: this.$gettext('No Contract')}
        ];
      },
      placeholder() { return this.$gettext('Select a contract option'); },
    },
    methods: {
      onInputBoxChanged(value) {
        this.contract = value;
        // reset filter value
        if (value == '-1') this.contract = '';
        else this.contract = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.contract = '';
        }
      }
    }
  };
</script>

<style>
.contract-filter .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.contract-filter .sk-select__list {
  width: fit-content;
}

.contract-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.contract-filter {
  width: 100%;
}
</style>

(<template>
  <div class="translation-skills">
    <div class="translation-skills__header">
      <div class="translation-skills__header--left">
        <h1 class="translation-skills__header-title">{{ $gettext('Translation Skills') }}</h1>
      </div>
      <!--      <div>-->
      <!--        <button class="sk-btn sk-btn&#45;&#45;white translation-skills__header-create-button"-->
      <!--                @click="openCreateSkillModal">{{ $gettext('Add skill') }}</button>-->
      <!--      </div>-->
    </div>
    <div class="translation-skills__cont">

      <!------- All Tabs ------->
      <div class="translation-skills-tabs__cont">
        <div class="translation-skills__wrapper">
          <multiselect-tab v-for="tab in leftSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :tab="tab" />
        </div>
        <div class="translation-skills__wrapper">
          <multiselect-tab v-for="tab in rightSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :left-side-icon="tab.leftSideIcon"
                           :tab="tab" />
        </div>
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import ContractFilter from '@/components/grid/filters/ContractFilter';
  import AccountStatusFilter from '@/components/grid/filters/shared/AccountStatusFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells ----//
  import CId from '@/components/grid/cells/shared/CId';
  import CTranslator from '@/components/grid/cells/skill/translation/CTranslator';
  import CActiveFrom from '@/components/grid/cells/skill/translation/CActiveFrom';
  import CNotes from '@/components/grid/cells/skill/translation/CNotes';
  import CStatus from '@/components/grid/cells/skill/translation/CStatus';
  import {mapState} from 'vuex';
  import CSkillInfo from '@/components/grid/cells/skill/translation/CSkillInfo';
  import CContract from '@/components/grid/cells/skill/translation/CContract';
  import LanguagePairFilter from '@/components/grid/filters/shared/LanguagePairFilter';
  import CTranslationStats from '@/components/grid/cells/skill/translation/CTranslationStats';
  import TranslationQualificationFilter from '@/components/grid/filters/shared/TranslationQualificationFilter';
  import CCreatedAt from '@/components/grid/cells/skill/CCreatedAt';

  export default {
    components: {
      'person-filter': CustomerNameFilter,
      'id-filter': IdFilter,
      'language-pair-filter': LanguagePairFilter,
      'contract-filter': ContractFilter,
      'account-status-filter': AccountStatusFilter,
      'qualification-filter': TranslationQualificationFilter,
      'c-id': CId,
      'c-skill-info': CSkillInfo,
      'c-translator': CTranslator,
      'c-actives-from': CActiveFrom,
      'c-translation-stats': CTranslationStats,
      'c-notes': CNotes,
      'c-status': CStatus,
      'c-contract': CContract,
      'c-created-at': CCreatedAt,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'approved',
        fetchGridDataAction: 'AllTranslationSkillsStore/getTranslationSkills'
      };
    },
    computed: {
      ...mapState('AllTranslationSkillsStore', {
        translationSkillsData: (state) => state.translationSkillsData || {},
        updateTranslationSkillPageGrid: (state) => state.updateTranslationSkillPageGrid || {},
      }),
      statistics() { return this.translationSkillsData.statistics; },
      approvedCount() { return this.statistics?.approved; },
      learningCount() { return this.statistics?.learning; },
      pendingCount() { return this.statistics?.pending; },
      declinedCount() { return this.statistics?.declined; },
      allCount() { return this.statistics?.all; },
      anniversariesCount() { return this.statistics?.anniversary; },
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            minWidth: 120,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Created at'),
           maxWidth: 150,
           flex: 1,
           field: 'createdAt',
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           valueFormatter: this.dateFormatter,
           filterParams: this.dateFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-created-at',
               params: params
             };
           }},
          {headerName: this.$gettext('Language, qualification and status'),
           field: 'langId',
           minWidth: 360,
           floatingFilterComponent: 'language-pair-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-skill-info',
               params: params
             };
           }},
          {headerName: this.$gettext('Name, email, phone'),
           field: 'lookupFields',
           minWidth: 300,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'person-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-translator',
               params: params
             };
           }},
          {headerName: this.$gettext('Status'),
           field: 'accountStatusName',
           width: 140,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'account-status-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-status',
               params: params
             };
           }},
          {headerName: this.$gettext('Active from'),
           field: 'qualificationId',
           flex: 2,
           cellClass: 'center-grid-column-data',
           floatingFilterComponent: 'qualification-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-actives-from',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Contract'),
           field: 'hasContract',
           maxWidth: 150,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'contract-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-contract',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Translation stats'),
           field: 'translationStats',
           minWidth: 120,
           cellRendererSelector: (params) => {
             return {
               component: 'c-translation-stats',
               params: params
             };
           }},
          {headerName: this.$gettext('Notes'),
           minWidth: 300,
           maxWidth: 500,
           field: 'notes',
           cellRendererSelector: (params) => {
             return {
               component: 'c-notes',
               params: params
             };
           }},
        ];
      },
      gridData() {
        return {
          page: this.currentPage,
          items: this.items,
          ransackParams: this.tabRansackParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'approved':
            return {
              's[status_eq]': 30,
            };
          case 'learning':
            return {
              's[status_eq]': 20
            };
          case 'pending':
            return {
              's[status_eq]': 10
            };
          case 'declined':
            return {
              's[status_eq]': 40
            };
          case 'anniversaries':
            return {
              's[with_anniversary_today]': true
            };
          case 'all':
            return {};
        }
      },
      isApprovedTab() { return this.currentView === 'approved'; },
      isLearningTab() { return this.currentView === 'learning'; },
      isPendingTab() { return this.currentView === 'pending'; },
      isDeclinedTab() { return this.currentView === 'declined'; },
      isAllView() { return this.currentView === 'all'; },
      isAnniversariesView() { return this.currentView === 'anniversaries'; },
      leftSideTabs() {
        return [
          {
            tab: 'approved',
            isActive: this.isApprovedTab,
            title: this.$gettext('Approved'),
            count: this.approvedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'learning',
            isActive: this.isLearningTab,
            title: this.$gettext('Learning'),
            count: this.learningCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'pending',
            isActive: this.isPendingTab,
            title: this.$gettext('Pending'),
            count: this.pendingCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'declined',
            isActive: this.isDeclinedTab,
            title: this.$gettext('Declined'),
            count: this.declinedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          },
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.allCount,
            onClick: this.setCurrentView
          }
        ];
      },
      rightSideTabs() {
        return [
          {
            tab: 'anniversaries',
            isActive: this.isAnniversariesView,
            title: this.$gettext('Anniversaries'),
            count: this.anniversariesCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            leftSideIcon: 'anniversary'
          }
        ];
      }
    },
    watch: {
      updateTranslationSkillPageGrid() {
        this.updateGridData();
      }
    },
    methods: {
      openCreateSkillModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-inter-skill-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Add skill'),
            context: this,
            successHandlingCallbackName: 'updateGridData'
          }
        });
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'approved', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style scoped>
.translation-skills {
  margin: 10px 20px;
}

.translation-skills__header-create-button {
  padding-right: 15px;
  padding-left: 40px;
  border: 1px solid #d3d5db;
  background-color: #fff;
  background-image: var(--plus-icon);
  background-position: 5.55% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.translation-skills__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.translation-skills-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.translation-skills__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.translation-skills__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.translation-skills__header--left {
  display: flex;
}

.translation-skills__header-title {
  font-size: 24px;
}
</style>

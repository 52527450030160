<script>
  import MultiSelectFilter from '@/components/grid/filters/shared/MultiSelectFilter';

  export default {
    extends: MultiSelectFilter,
    computed: {
      placeholder() { return this.$gettext('Select status'); },
      selectList() {
        return [
          {id: '0', name: this.$gettext('Active')},
          {id: '1', name: this.$gettext('Paused')},
          {id: '2', name: this.$gettext('Blocked')},
          {id: '3', name: this.$gettext('Retired')},
          {id: '4', name: this.$gettext('Banned')},
          {id: '5', name: this.$gettext('Deleted')},
          {id: '6', name: this.$gettext('Deceased')},
          {id: '7', name: this.$gettext('Inactive')}
        ];
      },
    },
  };
</script>

<script>
  import CNotes from '@/components/grid/cells/skill/CNotes';

  export default {
    extends: CNotes,
    methods: {
      onNoteClick() {
        this.$store.commit('UserNotesStore/setUserInfo', this.params.data);
        this.$store.commit('UserNotesStore/setCurrentUserId', this.params?.data.person.uid);
        this.$store.commit('ModalStore/setModal', {
          component: 'notes-modal',
          classes: ['sk-modal--new'],
          width: 750,
          data: {
            title: this.$gettext('Notes'),
            context: this,
            successHandlingCallbackName: 'refreshGrid'
          }
        });
      },
      refreshGrid() {
        this.$store.commit('AllTranslationSkillsStore/refreshTranslationSkillGrid');
      }
    }
  };
</script>

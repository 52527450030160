(<template>
  <div class="contact">
    <div class="contract-wrapper">
      <span v-if="hasContract"
            :class="{'tick-icon': hasContract}"></span>
      <p class="contract-status">{{ standByStatus }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      hasContract() { return this.propData?.contract?.hasContract; },
      standByStatus() {
        if (this.hasContract) return this.$gettext('Yes');
        else return '-';
      },
    }
  };
</script>
<style scoped>
.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.contract-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.tick-icon,
.star-icon {
  width: 16px;
  height: 19px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/tick_icon.svg);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>
